Array.from(document.querySelectorAll('[data-piece]')).map(
	async (el) => {
		let data;
		try {
			const res = await fetch(`/assets/pieces/${el.dataset.piece}`)
			if (res.status != 200) {
				console.error(`Error fetching pieces. Status: ${res.status}. ${res.statusText}`)
			}
			data = await res.text();
		} catch (e) {
			console.error('Error fetching pieces', e);
			el.previousElementSibling.innerHTML = `Error loading piece.`;
			return
		}

		if (!data) return;

		//insert text data into a proxy div & extract title information
		let proxyDiv = document.createElement('div');
		proxyDiv.insertAdjacentHTML('afterbegin', `${data}`);
		let h2 = proxyDiv.querySelector('h2').textContent;
		let h3 = proxyDiv.querySelector('h3').textContent;

		//insert piece CONTENT into the document
		el.insertAdjacentHTML('afterbegin', `${data}`);

		//set titles and subtitles programmatically for container
		let h3ToUpdate = el
			.closest('.window-to-open')
			.previousElementSibling.querySelector('h3');
		let h4ToUpdate = el
			.closest('.window-to-open')
			.previousElementSibling.querySelector('h4');
		h3ToUpdate.textContent = h2;
		h4ToUpdate = h3;
	}
);